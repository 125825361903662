<template>
  <div class="select-platform-page">
    <div class="section-content mx-auto">
      <div class="select-platform-header d-flex flex-wrap align-start justify-space-between">
        <h1 class="order-1 order-sm-0 flama-bold--font">Connect Roxie to your e-commerce platform</h1>
        <span class="mb-2 mb-sm-0 primary--text">Step 2 of 2</span>
      </div>

      <p class="select-platform-descr mb-8">Enter your store Shopify URL to connect with Roxie and start selling.
        You will be redirected to Roxie complete installation.</p>

      <v-radio-group v-model="platform">
        <div class="select-platform-radio-wrap d-flex flex-column flex-sm-row align-center align-sm-start flex-wrap">
          <v-radio value="shopify" class="mt-6 mb-16">
            <template v-slot:label>
              <img width="214" :src="require('@/assets/img/login/shopify.png')" alt="">
            </template>
          </v-radio>
          <div class="select-platform-disabled text-center text-sm-left">
            <span>Coming soon:</span>
            <v-radio value="woocommerce" class="mt-6 mb-4" disabled>
              <template v-slot:label>
                <img width="184" :src="require('@/assets/img/login/woocommerce.png')" alt="">
              </template>
            </v-radio>
            <v-radio value="magento" class="mb-4" disabled>
              <template v-slot:label>
                <img width="184" :src="require('@/assets/img/login/magento.png')" alt="">
              </template>
            </v-radio>
            <v-radio value="wix" class="mb-4" disabled>
              <template v-slot:label>
                <img width="184" :src="require('@/assets/img/login/wix.png')" alt="">
              </template>
            </v-radio>
            <v-radio value="squarespace" disabled>
              <template v-slot:label>
                <img width="184" :src="require('@/assets/img/login/squarespace.png')" alt="">
              </template>
            </v-radio>
          </div>
        </div>
      </v-radio-group>

      <div class="select-platform-buttons d-flex mt-10">
<!--        <v-btn-->
<!--          dark-->
<!--          depressed-->
<!--          outlined-->
<!--          type="submit"-->
<!--          height="auto"-->
<!--          color="grey-4"-->
<!--          class="py-4 mr-6"-->
<!--          @click="localStorage.removeItem('userInfo'); $router.push({name: 'MainPageLanding'})"-->
<!--        >-->
<!--          Cancel-->
<!--        </v-btn>-->
        <v-btn
          dark
          depressed
          outlined
          type="submit"
          height="auto"
          color="grey-4"
          class="py-4 mr-6"
          @click="$store.dispatch('SEND_USER_TO_SHOPIFY')"
        >
          Skip
        </v-btn>
        <v-btn
          dark
          depressed
          type="submit"
          height="auto"
          color="black"
          class="py-4"
          @click="$store.dispatch('SEND_USER_TO_SHOPIFY')"
        >
          <img class="mr-2" width="24" height="24" :src="require('@/assets/img/icons/ic-arrow-right-white.svg')"
               alt="">
          Connect
        </v-btn>
      </div>

<!--      <div class="error&#45;&#45;text mt-4" v-if="$store.getters.getErrorMessage">-->
<!--        {{$store.getters.getErrorMessage}}-->
<!--      </div>-->

    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginSelectPlatform',
  data() {
    return {
      platform: 'shopify'
    }
  },
  // methods: {
  //   register() {
  //     this.$store.dispatch('REGISTER',{
  //       company: {
  //         address: "",
  //         city: "",
  //         state: "",
  //         country: "",
  //         postal_code: "",
  //         timezone: "",
  //         users_attributes: [
  //           {
  //             first_name: "",
  //             last_name: "",
  //             phone_number: "",
  //             ...this.$store.getters.getUserInfo,
  //           }
  //         ]
  //       }
  //     });
  //   }
  // },
  // beforeDestroy() {
  //   this.$store.commit('SET_ERROR_MESSAGE', '');
  // },
}
</script>
